import React, { useState } from "react";
import Layout from "../../components/Layout";
import { Container, Row, Col } from "reactstrap";
import Banner from "../../images/Product/product-banner.jpg";
import { navigate } from "gatsby-link";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import {
  useFirebaseApp,
  useFirestore,
  useFirestoreCollectionData,
} from "reactfire";

const Products = () => {
  const firebase1 = useFirebaseApp();
  const firestore = useFirestore(firebase1);
  const catRef = firestore.collection("Categories");


  let { data } = useFirestoreCollectionData(catRef);

  const getProducts = (products) => {
    return products.map((productData) => {
      return (
        <Col md="4">
          <div
            className="product"
            onClick={() => onClickHandler(productData.name)}
          >
            <img src={productData.icon} alt="image" />
            <h2>{productData.name}</h2>
          </div>
        </Col>
      );
    });
  };

  const onClickHandler = (productName) => {
    navigate("Product", { state: { product: productName } });
  };

  return (
    <Layout>
      <section className="product_banner">
        <Container>
          {/* <Row>
                        <Col md="4"> */}
          <div>
            <img src={Banner} alt="img" />
          </div>
          {/* </Col>
                    </Row> */}
        </Container>
      </section>
      <section className="product_section_main">
        <Container>
          <Row>{data != undefined && getProducts(data)}</Row>
        </Container>
      </section>
    </Layout>
  );
};


export default Products;
